
@font-face {
  font-family: 'amithenregular';
  src: 
       url('/files/amithen-webfont.woff2') format('woff2'),
       url('/files/amithen-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

$font-family-base: Helvetica, 'Helvetica Neue', Arial, 'Lucida Grande', sans-serif;
$font-size-base: 0.9375rem;
$h1-font-size:                $font-size-base * 2.1;
$h2-font-size:                $font-size-base * 1.7;
$h3-font-size:                $font-size-base * 1.4;
$h4-font-size:                $font-size-base * 1.2;
$h5-font-size:                $font-size-base * 1.1;
$h6-font-size:                $font-size-base;

$headings-font-weight:        700;

$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem
);

$display-font-weight: null;


// COLORS
$primary: #b16139; // UPDATED
$secondary: #b16139; // UPDATED
$info: #EFF0F2;

$success:#45736A; // UPDATED
$warning:#D9C116;
$danger:#D4164B;

$light: #f6f6f6; // UPDATED
$gray: #8c8686; // UPDATED - Overlay 
$disabled: #6C757D;
$dark: #333232; // UPDATED
$component-active-bg: $success;


$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "gray":       $gray,
  "disabled":   $disabled,
  "dark":       $dark,
  "white":      #ffffff
);

$headings-margin-bottom: 0;
$paragraph-margin-bottom: 0;
$input-focus-box-shadow: 0;
$enable-negative-margins: true;
$font-weight-bold: 700;
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,

  6: $spacer * 4.5,
  7: $spacer * 6,
  8: $spacer * 7.5,
  9: $spacer * 9,
  10: $spacer * 10.5
);
$dropdown-padding-y: 0;
$dropdown-border-radius: 0;
$dropdown-item-padding-y: 0.5em;

// BUTTONS

$btn-border-radius: 0;
$btn-font-size: 13px;
$btn-font-weight: 700;
$btn-padding-x: 1.5em;
$btn-padding-y: 0.7em;



$btn-border-radius-sm: 0;
$btn-padding-x-sm: 1em;
$btn-padding-y-sm: 0.466em;




@import '../node_modules/bootstrap/scss/bootstrap.scss';

.btn {
  text-transform: uppercase;
  letter-spacing: 0.08em;
}


body {
  -webkit-font-smoothing: antialiased;
  overflow-y: auto;
  scrollbar-gutter: stable;
}

.full-page {
    min-height: 100svh;
}

.logo {
    max-width: 3rem;
}

.hero-banner .text-white {
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  font-weight: 700;
}

.bg-overlay {
  position: relative;
  z-index: 2;
}
.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.bg-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--overlaycolor);
  opacity: var(--overlayopacity);
  z-index: -1;
}

.nav-link-active {
    text-decoration: underline;
}
.nav-link:hover {
    text-decoration: underline;
}

.pricing-title{
  font-family: "amithenregular";
  font-size: 3rem;
  line-height: 3rem;
  font-weight:  normal;
  margin-bottom: 2.5rem !important;
  margin-top: 1.5rem;
  color: $primary;
}

.pricing-subtitle {
  text-transform: uppercase;
  letter-spacing: .08em;
  font-weight: 700;
  font-size: 0.9rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  color: $success;
}